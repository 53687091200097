import { restPut } from "../../../store/api";
import { mainBranches } from "../../../components/private-layout/data";
import { getUserDetails } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
export default function NewDashboard({ setShowOldDashboard }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [check, setCheck] = useState(user?.defaultDashboard);

  function makeDefaultDashboard(checked) {
    let reqBody = { defaultDashboard: false };
    if (checked) {
      restPut(
        "/users/update",
        reqBody,
        (data) => {
          dispatch(getUserDetails(data));
        },
        (err) => console.error(err)
      );
      window.location.reload();
    }
  }

  return (
    <>
      <div className="w-full bg-white pt-8 px-8 pb-4 rounded-[70px] 2xl:rounded-[80px]">
        <div className="flex items-center justify-center">
          <div className="text-primary-green font-bold text-xl mr-4">
            ALL BRANCHES
          </div>

          {check && (
            <div className="text-sm">
              <input
                className="mr-2"
                type="checkbox"
                onClick={(e) => makeDefaultDashboard(e.target.checked)}
              />
              Make this the default dashboard
            </div>
          )}
        </div>

        <div className="mx-auto w-full mt-8 mb-4">
          <ul className="grid grid-cols-6 lg:grid-cols-6 2xl:grid-cols-6 gap-y-4 gap-x-8 sm:gap-x-4 md:gap-x-8 lg:gap-x-2 2xl:gap-x-10">
            {mainBranches.map((mainBranch) => (
              <>
                {mainBranch.subBranches
                  .filter((subBranch) => subBranch.path !== "my-contacts")
                  .map((subBranch, index) => (
                    <li key={index} className="relative">
                      <a
                        className="block group mx-auto overflow-hidden rounded-lg"
                        style={{ position: "relative", cursor: "pointer" }}
                        href={mainBranch.path + "/" + subBranch.path}
                      >
                        <img
                          src={subBranch.favoriteIcon}
                          alt={`Go to ${subBranch.image.imgAlt}`}
                          className="object-cover group-hover:opacity-75 transition duration-300 w-full h-auto"
                        />
                        <p className="mt-2 block text-3xs sm:text-sm lg:text-3xs xl:text-2xs 2xl:text-xs font-medium text-gray-900 text-center uppercase">
                          {`${subBranch.name.slice(0, -7)}`}
                        </p>
                      </a>
                    </li>
                  ))}
              </>
            ))}
          </ul>
        </div>

        {check && (
          <div
            className="text-right underline text-sm font-medium text-blue-600 cursor-pointer"
            onClick={() => setShowOldDashboard(true)}
          >
            Return to classic dashboard
          </div>
        )}
      </div>
    </>
  );
}
