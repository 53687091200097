import { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { restGet, restPut } from "../../store/api";
import { getUserDetails } from "../../store/actions";
import { RenderFavorites } from "../../components/dashboard/render-favorites";
import { scrollTop } from "../../components/common/scroll-top";
import OldDashboard from "./all-branches/old-dashboard";
import NewDashboard from "./all-branches/new-dashboard";

const topics = [
  {
    link: "inner-circle",
    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/inner-circle-icon-green.svg",
    alt: "inner circle",
  },
  {
    link: "sharing",
    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/Sharing-Green-01.svg",
    alt: "sharing",
  },
  {
    link: "reports",
    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/to-do-list-icon.svg",
    alt: "reports",
  },
  {
    link: "the-tracker",
    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/topics-overview.svg",
    alt: "the tracker",
  },
  {
    link: "to-do-list",
    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/to-do-list-icon.svg",
    alt: "to do list",
  },
  {
    link: "unexpected",
    img: "https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/unexpected-icon-Green-01.svg",
    alt: "unexpected",
  },
];

export default function Home({ setShowReset, setShowHome }) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [isNewDashboard, setIsNewDashboard] = useState(user?.defaultDashboard);
  const [showOldDashboard, setShowOldDashboard] = useState(
    user?.defaultDashboard
  );

  const type = user?.type;

  const resetPriorities = (prevFavs) => {
    const reqBody = { favorites: prevFavs };

    restPut("/users/update", reqBody, (data) =>
      dispatch(getUserDetails(data), (err) => console.error(err))
    );

    setShowReset(true);
    setShowHome(false);

    navigate("/");
  };

  const setQuickStart = () => {
    const reqBody = { quickstart: true };
    restPut("/users/update", reqBody, (data) =>
      dispatch(getUserDetails(data), (err) => console.error(err))
    );

    navigate("/");
  };

  const successCallback = (dt) => {
    setData(dt);
  };

  const failCallback = (err) => {
    console.error(err);
  };

  const revertToClassicDashboard = () => {
    restPut(
      "/users/update",
      { defaultDashboard: true },
      (data) => {
        dispatch(getUserDetails(data));
      },
      (err) => console.error(err)
    );
    window.location.reload();
  };

  useLayoutEffect(() => {
    const getDataToShow = () => {
      restGet("/users/visited", successCallback, failCallback);
    };

    getDataToShow();
  }, [user]);

  return (
    <div className="mb-8">
      <div className="mx-auto flex max-w-7xl flex-col pb-16 md:pb-0 pt-10 md:pt-6 lg:pt-8">
        <div className="bg-white shadow sm:rounded-lg">
          <header className="relative px-4 py-6 md:px-6">
            <div className="flex items-center justify-between">
              <h1 className="text-3xl uppercase text-primary-green absolute left-1/2 transform -translate-x-1/2">
                My Dashboard
              </h1>

              {/* Revert Button */}
              {!isNewDashboard && (
                <div
                  className="flex items-center cursor-pointer ml-auto"
                  onClick={() => revertToClassicDashboard()}
                >
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 21 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#93a68d"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      transform="matrix(0 1 1 0 2.5 2.5)"
                    >
                      <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8" />
                      <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)" />
                    </g>
                  </svg>
                  <h1 className="text-primary-green text-sm ml-2">
                    Revert to Classic Dashboard
                  </h1>
                </div>
              )}
            </div>
          </header>

          <main className="px-4 pb-8 sm:px-20 lg:px-16 2xl:px-20 border-t border-gray-300 bg-alternative-green sm:rounded-b-lg">
            {/* DESKTOP */}
            <div className="mt-10 hidden lg:flex flex-col w-full gap-8 2xl:gap-12">
              {/* DESKTOP CURRENT BRANCHES */}
              {!showOldDashboard ? (
                <NewDashboard setShowOldDashboard={setShowOldDashboard} />
              ) : (
                <OldDashboard
                  user={user}
                  data={data}
                  type={type}
                  setQuickStart={setQuickStart}
                  resetPriorities={resetPriorities}
                  setShowOldDashboard={setShowOldDashboard}
                />
              )}

              {/* SEVEN TABS */}
              <div className="grid grid-cols-6 pb-2 bg-white rounded-full">
                {topics.map((topic) => (
                  <div
                    key={topic.alt}
                    className="h-max flex flex-col justify-between"
                  >
                    {type === "readonly" && topic.link !== "sharing" ? (
                      <Link
                        to=""
                        onClick={scrollTop}
                        className="group flex justify-center cursor-not-allowed"
                      >
                        <img
                          src={topic.img}
                          alt={`${topic.alt} icon`}
                          className={`pointer-events-none w-14 2xl:w-16 ${
                            topic.alt.toLowerCase() === "the tracker" && "m-2"
                          }`}
                        />
                      </Link>
                    ) : (
                      <Link
                        to={`/${topic.link}`}
                        onClick={scrollTop}
                        className="group flex justify-center"
                      >
                        <img
                          src={topic.img}
                          alt={`${topic.alt} icon`}
                          className={`pointer-events-none group-hover:opacity-75 transition duration-300 w-14 2xl:w-16 ${
                            topic.alt.toLowerCase() === "the tracker" && "m-2"
                          }`}
                        />
                      </Link>
                    )}

                    <p className="pointer-events-none text-3xs 2xl:text-xs font-medium text-gray-900 text-center uppercase">
                      {topic.alt}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* MOBILE */}
            <div className="mt-16 lg:hidden flex flex-col w-full gap-10">
              <div className="flex flex-col items-center justify-between gap-16 mb-10">
                {/* MOBILE CURRENT BRANCHES */}
                {!showOldDashboard ? (
                  <NewDashboard setShowOldDashboard={setShowOldDashboard} />
                ) : (
                  <div className="min-w-full h-min bg-white flex flex-col gap-10 p-8 rounded-[70px]">
                    <div className="w-full flex items-center justify-center gap-6">
                      <h2 className="uppercase font-medium text-lg sm:text-2xl text-primary-green">
                        Current Branches
                      </h2>

                      <button
                        onClick={() => {
                          if (type !== "readonly") {
                            resetPriorities(user.favorites);
                          }
                        }}
                        className={`group ${
                          type !== "readonly"
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                        } flex flex-col justify-center items-center`}
                      >
                        <img
                          src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/reset.svg"
                          alt="priority branches icon"
                          className={`h-6 w-6 ${
                            type !== "readonly" &&
                            "group-hover:animate-spin-slow transition duration-300"
                          }`}
                        />
                        <p className="text-sm text-primary-green">Reset</p>
                      </button>
                    </div>

                    <div className="h-full flex items-center">
                      <RenderFavorites
                        user={user}
                        setQuickStart={setQuickStart}
                      />
                    </div>

                    <div className="w-full border-t border-primary-green"></div>

                    <div
                      className="flex items-center justify-center cursor-pointer"
                      onClick={() => setShowOldDashboard(false)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="10"
                          y="3"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="17"
                          y="3"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="3"
                          y="10"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="10"
                          y="10"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="17"
                          y="10"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="3"
                          y="17"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="10"
                          y="17"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                        <rect
                          x="17"
                          y="17"
                          width="4"
                          height="4"
                          rx="1"
                          fill="#93A68D"
                        />
                      </svg>
                      <span className="ml-2 text-black text-sm">
                        SEE ALL BRANCHES
                      </span>
                    </div>
                  </div>
                )}

                {/* FIRST THREE TABS */}
                <div className="mx-auto w-full grid grid-cols-3 grid-row-2 gap-y-8 gap-x-8 sm:gap-x-20 md:gap-x-28">
                  <div className="col-span-3 flex justify-evenly items-center bg-white rounded-full px-2 pb-4">
                    {topics.slice(0, 3).map((topic) => (
                      <div key={topic.alt}>
                        {type === "readonly" && topic.link !== "sharing" ? (
                          <Link
                            to=""
                            onClick={scrollTop}
                            className="group flex justify-center cursor-not-allowed"
                          >
                            <img
                              src={topic.img}
                              alt={`${topic.alt} icon`}
                              className={`pointer-events-none w-10 2xl:w-16 ${
                                topic.alt.toLowerCase() === "the tracker" &&
                                "mt-2"
                              }`}
                            />
                          </Link>
                        ) : (
                          <Link
                            to={`/${topic.link}`}
                            onClick={scrollTop}
                            className="group flex justify-center"
                          >
                            <img
                              src={topic.img}
                              alt={`${topic.alt} icon`}
                              className={`pointer-events-none group-hover:opacity-75 transition duration-300 w-10 2xl:w-16 ${
                                topic.alt.toLowerCase() === "the tracker" &&
                                "mt-2"
                              }`}
                            />
                          </Link>
                        )}

                        <p className="pointer-events-none text-3xs sm:text-xs md:text-sm font-medium text-gray-900 text-center uppercase">
                          {topic.alt}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-between gap-16">
                {data ? (
                  data.lastVisited.length > 0 ? (
                    // MOBILE RECENT TOPICS
                    <div className="w-full h-full bg-white flex flex-col gap-10 p-8 rounded-[70px] bg-[url('https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/greenbanyan_lessOpacity-2-01.svg')] bg-no-repeat bg-contain bg-center">
                      <h2 className="uppercase font-medium text-lg sm:text-2xl text-primary-green text-center">
                        Recent Topics
                      </h2>

                      <div className="w-full max-h-full">
                        {data &&
                          data.lastVisited
                            .reverse()
                            .slice(0, 5)
                            .map((el, index) => (
                              <div
                                key={el.name}
                                className="w-full flex flex-col items-start gap-4"
                              >
                                {type !== "readonly" ? (
                                  <Link to={el.path} onClick={scrollTop}>
                                    <p className="text-primary-green font-medium text-2xs 2xl:text-sm hover:opacity-80 hover:underline transition duration-300">
                                      {el.name}
                                    </p>
                                  </Link>
                                ) : (
                                  <p className="text-primary-green font-medium text-2xs 2xl:text-sm">
                                    {el.name}
                                  </p>
                                )}

                                <hr
                                  className={`w-full border-1 ${
                                    index + 1 !==
                                    data.lastVisited.slice(0, 5).length
                                      ? "border-primary-green"
                                      : "border-transparent"
                                  }`}
                                />
                              </div>
                            ))}
                      </div>
                    </div>
                  ) : (
                    // MOBILE QUICKSTART
                    <div className="w-full h-full bg-white flex flex-col gap-8 px-8 pb-8 pt-6 rounded-[70px] 2xl:rounded-[80px]">
                      <div className="w-full flex items-center justify-center gap-2">
                        <h2 className="uppercase font-medium text-xl 2xl:text-2xl text-primary-green">
                          QUICKSTART
                        </h2>

                        <img
                          src="https://mybanyan-bucket-staging.s3.us-west-1.amazonaws.com/app/dashboard/icons/quickstart-icon-GreeN-01.svg"
                          alt="priority branches icon"
                          className="h-12 w-12 group-hover:animate-spin-slow transition duration-300"
                        />
                      </div>

                      <p className="text-xs text-primary-green">
                        To get the most out of My Banyan, we recommend that you
                        view the simple QuickStart Guide below.
                      </p>

                      <div className="flex justify-center">
                        <iframe
                          className="sm:h-96 rounded-md"
                          src="https://customer-dt3dzeh2frr4vgqu.cloudflarestream.com/d12707d8ab6fd9073600bd20d7e82192/iframe?preload=true&poster=https%3A%2F%2Fcustomer-dt3dzeh2frr4vgqu.cloudflarestream.com%2Fd12707d8ab6fd9073600bd20d7e82192%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s%26height%3D600"
                          width="100%"
                          height="100%"
                          title={"QuickStart Guide Video"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div>
                    </div>
                  )
                ) : (
                  <div className="w-full h-full bg-white flex flex-col gap-10 p-10 rounded-[70px]" />
                )}

                {/* LAST THREE TABS */}
                <div className="mx-auto w-full grid grid-cols-3 grid-row-2 gap-y-8 gap-x-8 sm:gap-x-20 md:gap-x-28">
                  <div
                    className={`col-span-3 flex justify-evenly items-center bg-white rounded-full px-2 pb-4 `}
                  >
                    {topics.slice(3).map((topic) => (
                      <div
                        key={topic.alt}
                        className="h-full flex flex-col justify-between"
                      >
                        {type === "readonly" ? (
                          <Link
                            to=""
                            onClick={scrollTop}
                            className="group flex justify-center cursor-not-allowed"
                          >
                            <img
                              src={topic.img}
                              alt={`${topic.alt} icon`}
                              className={`pointer-events-none w-10 2xl:w-16 ${
                                topic.alt.toLowerCase() === "the tracker" &&
                                "mt-2"
                              }`}
                            />
                          </Link>
                        ) : (
                          <Link
                            to={`/${topic.link}`}
                            onClick={scrollTop}
                            className="group flex justify-center"
                          >
                            <img
                              src={topic.img}
                              alt={`${topic.alt} icon`}
                              className={`pointer-events-none group-hover:opacity-75 transition duration-300 w-10 2xl:w-16 ${
                                topic.alt.toLowerCase() === "the tracker" &&
                                "mt-2"
                              }`}
                            />
                          </Link>
                        )}

                        <p className="pointer-events-none text-3xs sm:text-xs md:text-sm font-medium text-gray-900 text-center uppercase">
                          {topic.alt}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <footer className="mt-8 flex justify-center items-center gap-x-4">
              <a
                href="https://compliancy-group.com/hipaa-compliance-verification/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/HIPAA-Seal-of-Compliance-Hi-res-600x319.png?alt=media&token=ab62752e-cbda-49aa-8c0b-58a0f3bc69ff"
                  alt="HIPAA Seal of Compliance"
                  className="pointer-events-none group-hover:opacity-75 transition duration-300 w-28"
                />
              </a>

              <p className="text-xs sm:text-sm text-center">
                © {new Date().getFullYear()} My Banyan, Inc.
              </p>
            </footer>
          </main>
        </div>
      </div>
    </div>
  );
}
