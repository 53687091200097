import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";

import RegJourneyModal from "./reg-journey-modal";
import { mainBranches } from "../../../components/private-layout/data";
import { restPut } from "../../../store/api";
import { getUserDetails } from "../../../store/actions";

const getUserBranches = (data) => {
  const values = {
    aboutMe: [],
    myEveryday: [],
    myEventual: [],
    myUnexpected: [],
  };

  data.forEach((el) => {
    var arr = el.path.split("/");
    if (arr[1] === "about-me") values.aboutMe.push(el.path);
    if (arr[1] === "everyday") values.myEveryday.push(el.path);
    if (arr[1] === "eventual") values.myEventual.push(el.path);
    if (arr[1] === "unexpected") values.myUnexpected.push(el.path);
  });

  return values;
};

// Removes the mandatory branches from the user's chosen branches
const removeMandatoryBranches = (userBranches) => {
  const branchesToRemove = ["/about-me/my-essentials", "/about-me/my-family"];

  if (userBranches.hasOwnProperty("aboutMe") && Array.isArray(userBranches.aboutMe)) {
    userBranches.aboutMe = userBranches.aboutMe.filter((item) => !branchesToRemove.includes(item));

    return userBranches;
  }
};

// Checks to see if the user has at least one chosen branch
const areUserBranchesEmpty = (userBranches) => Object.values(userBranches).every((arr) => Array.isArray(arr) && arr.length === 0);

export default function SelectBranches({ setDialogNum }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const user = useSelector((state) => state.auth.user);
  let userBranches = removeMandatoryBranches(getUserBranches(user.favorites));

  // Sets four branches if the user doesn't have any chosen branches
  if (areUserBranchesEmpty(userBranches)) {
    userBranches = {
      myEveryday: ["/everyday/accounts", "/everyday/finances", "/everyday/health"],
      myEventual: ["/eventual/estate-plans"],
    };
  }

  const aboutMeBranch = mainBranches[0].subBranches;
  const myEverydayBranch = mainBranches[1].subBranches.slice(1, 9);
  const myEventualBranch = mainBranches[2].subBranches;

  const branchDesc = {
    "my essentials branch": "Birth info, legal identification info, employment, and more.",
    "my family branch": "Names, relationships, important dates, pets, and more.",
    "my life experiences branch": "Accomplishments, significant events, and more.",
    "accounts branch": "Banks, utilities, credit cards., investments, and more.",
    "business branch": "Legal documents, financial statements, stock certificates, permits, minutes, and more.",
    "digital world branch": "Internet access, email services, passwords, software, and more.",
    "finances branch": "Financial plans, income sources, assets, liabilities, and more.",
    "health branch": "Living will, health power of attorney, current exams, medications, allergies, vaccinations, and more.",
    "insurance branch": "Policies covering life, health, long-term care, and more.",
    "legal issues branch": "Lawsuits and bankruptcy filings.",
    "real estate branch": "Properties, deeds, leases, security measures, and more.",
    "estate plans branch": "Wills, trusts, gifting, transfers on death, powers of attorney, and more.",
    "senior life plans branch": "Living options, finance, care providers, and more.",
    "legacy wishes branch": "Legacy statements, charities supported, memorabilia, and more.",
    "farewell plans branch": "Ceremonial preferences, participants, communications, finance, and more.",
  };

  const getReqBody = (values) => {
    var array = [];

    if (values.aboutMe) {
      values.aboutMe.forEach((el) => {
        var s = el.split("/")[2];
        array.push({
          path: el,
          favoriteIcon: mainBranches[0].subBranches.find((sub) => sub.path === s).favoriteIcon,
          name: mainBranches[0].subBranches.find((sub) => sub.path === s).name,
        });
      });
    }
    if (values.myEveryday) {
      values.myEveryday.forEach((el) => {
        var s = el.split("/")[2];
        array.push({
          path: el,
          favoriteIcon: mainBranches[1].subBranches.find((sub) => sub.path === s).favoriteIcon,
          name: mainBranches[1].subBranches.find((sub) => sub.path === s).name,
        });
      });
    }
    if (values.myEventual) {
      values.myEventual.forEach((el) => {
        var s = el.split("/")[2];
        array.push({
          path: el,
          favoriteIcon: mainBranches[2].subBranches.find((sub) => sub.path === s).favoriteIcon,
          name: mainBranches[2].subBranches.find((sub) => sub.path === s).name,
        });
      });
    }
    if (values.myUnexpected) {
      values.myUnexpected.forEach((el) => {
        var s = el.split("/")[2];
        array.push({
          path: el,
          favoriteIcon: mainBranches[3].subBranches.find((sub) => sub.path === s).favoriteIcon,
          name: mainBranches[3].subBranches.find((sub) => sub.path === s).name,
        });
      });
    }

    return array;
  };

  // Checks to see how many branches were chosen
  const validateChosenBranches = (values) => {
    let count = 0;

    if (values.aboutMe) {
      count += values.aboutMe.length;
    }
    if (values.myEveryday) {
      count += values.myEveryday.length;
    }
    if (values.myEventual) {
      count += values.myEventual.length;
    }
    if (values.myUnexpected) {
      count += values.myUnexpected.length;
    }

    return count;
  };

  // Removes 'branch' from the sentence
  const removeBranch = (branchName) => {
    const regex = /\bbranch\b/gi;

    const result = branchName.replace(regex, "");

    return result.replace(/\s+/g, " ").trim();
  };

  const submitBranches = (values) => {
    let reqBody = {};

    if (validateChosenBranches(values) === 4) {
      if (!Array.isArray(values.aboutMe)) {
        values.aboutMe = [];
      }

      values.aboutMe.push("/about-me/my-essentials");
      values.aboutMe.push("/about-me/my-family");

      reqBody.favorites = getReqBody(values);
      reqBody.quickstart = false;

      restPut(
        "/users/update",
        reqBody,
        (data) => {
          dispatch(getUserDetails(data));
        },
        (err) => console.error(err)
      );

      setDialogNum(4);
    }
  };

  return (
    <RegJourneyModal open={open} setOpen={setOpen}>
      <div className="w-full h-[7vh] lg:pb-5 2xl:pb-0 lg:h-[10vh] 2xl:h-[7vh] flex justify-center items-center lg:items-end 2xl:items-center text-2xl md:text-3xl text-primary-green text-center font-medium">
        <h2>WELCOME</h2>
      </div>

      <section className="h-max p-8 flex justify-center items-center bg-alternative-green">
        <div className="h-full flex flex-col justify-between gap-y-4 font-custom2">
          <p className="md:text-lg text-justify">
            There are 15 branches of My Banyan. They cover a wide range of life issues. These branches can bring together information and documents that you could share with members of your Inner
            Circle.
          </p>

          <p className="md:text-lg text-justify">
            We have preselected 6 branches for you that are the most popular with new users. These branches will appear on your personalized Dashboard. You can modify your branches at any time.
          </p>

          <Form
            onSubmit={submitBranches}
            initialValues={user.favorites && userBranches}
            render={({ handleSubmit, submitting, values }) => {
              const selectedCount = Object.values(values).flat().length;

              return (
                <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-x-8">
                  <div className="flex-1">
                    {/* ABOUT ME checkboxes */}
                    <section>
                      <h2 className="text-xl md:text-2xl text-primary-green font-semibold text-center">ABOUT ME</h2>
  
                      {aboutMeBranch.map((sub) => (
                        <div key={sub.name} className="my-2 flex items-center gap-x-4">
                          {sub.name.toLowerCase() === "my life experiences branch" ? (
                            <Field
                              name="aboutMe"
                              type="checkbox"
                              component="input"
                              value={`/about-me/${sub.path}`}
                              id={`aboutMe_${sub.path}`}
                              className="w-10 h-10 text-primary-green focus:ring-primary-green"
                            />
                          ) : (
                            <Field
                              name="aboutMe"
                              type="checkbox"
                              component="input"
                              value={`/about-me/${sub.path}`}
                              id={`aboutMe_${sub.path}`}
                              className="w-10 h-10 text-primary-green focus:ring-0 cursor-not-allowed"
                              checked
                            />
                          )}
  
                          <label htmlFor={`aboutMe_${sub.path}`} className="flex flex-col">
                            <p className="md:text-lg text-dark-red font-medium capitalize">{removeBranch(sub.name)}</p>
  
                            <p className="text-sm md:text-base">{branchDesc[sub.name.toLowerCase()]}</p>
                          </label>
                        </div>
                      ))}
                    </section>
  
                    {/* MY EVERYDAY checkboxes (left side) */}
                    <section className="pt-6">
                      <h2 className="text-xl md:text-2xl text-primary-green font-semibold text-center">MY EVERYDAY</h2>
  
                      {myEverydayBranch.slice(0, 5).map((sub) => (
                        <div key={sub.name} className="my-2 flex items-center gap-x-4">
                          <Field
                            name="myEveryday"
                            type="checkbox"
                            component="input"
                            value={`/everyday/${sub.path}`}
                            id={`everyday_${sub.path}`}
                            className="w-10 h-10 text-primary-green focus:ring-primary-green"
                          />
  
                          <label htmlFor={`everyday_${sub.path}`} className="flex flex-col">
                            <p className="md:text-lg text-dark-red font-medium capitalize">{removeBranch(sub.name)}</p>
  
                            <p className="text-sm md:text-base">{branchDesc[sub.name.toLowerCase()]}</p>
                          </label>
                        </div>
                      ))}
                    </section>
                  </div>
  
                  <div className="flex-1 flex flex-col justify-between">
                    {/* MY EVERYDAY checkboxes (right side) */}
                    <section>
                      {myEverydayBranch.slice(5).map((sub) => (
                        <div key={sub.name} className="my-2 flex items-center gap-x-4">
                          <Field
                            name="myEveryday"
                            type="checkbox"
                            component="input"
                            value={`/everyday/${sub.path}`}
                            id={`everyday_${sub.path}`}
                            className="w-10 h-10 text-primary-green focus:ring-primary-green"
                          />
  
                          <label htmlFor={`everyday_${sub.path}`} className="flex flex-col">
                            <p className="md:text-lg text-dark-red font-medium capitalize">{removeBranch(sub.name)}</p>
  
                            <p className="text-sm md:text-base">{branchDesc[sub.name.toLowerCase()]}</p>
                          </label>
                        </div>
                      ))}
                    </section>
  
                    {/* MY EVENTUAL branches */}
                    <section className="pt-6">
                      <h2 className="text-xl md:text-2xl text-primary-green font-semibold text-center">MY EVENTUAL</h2>
  
                      {myEventualBranch.map((sub) => (
                        <div key={sub.name} className="my-2 flex items-center gap-x-4">
                          <Field
                            name="myEventual"
                            type="checkbox"
                            component="input"
                            value={`/eventual/${sub.path}`}
                            id={`eventual_${sub.path}`}
                            className="w-10 h-10 text-primary-green focus:ring-primary-green"
                          />
  
                          <label htmlFor={`eventual_${sub.path}`} className="flex flex-col">
                            <p className="md:text-lg text-dark-red font-medium capitalize">{removeBranch(sub.name)}</p>
  
                            <p className="text-sm md:text-base">{branchDesc[sub.name.toLowerCase()]}</p>
                          </label>
                        </div>
                      ))}
                    </section>

                    {/* SELECTION LIMIT MESSAGE */}
                    <p className="text-red-500 text-center">{selectedCount > 4 && "You can only select up to 6 options."}</p>
  
                    {/* TEXT and BUTTON */}
                    <section className="mt-6 sm:mt-0 flex items-center justify-end gap-x-8">
                      <button className="disabled:cursor-not-allowed" disabled={submitting || selectedCount !== 4} type="submit">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/my-banyan.appspot.com/o/client%2Fregistration-journey%2Fright%20arrow.svg?alt=media&token=ef3d9e22-73f8-41a8-b7d9-802f60348068"
                          alt="Green right arrow"
                          className="h-14 sm:h-20 w-14 sm:w-20"
                        />
                      </button>
                    </section>
                  </div>
                </form>
              )
            }}
          />
        </div>
      </section>
    </RegJourneyModal>
  );
}
